/* rubik-300 - latin */
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src:
    url("./../fonts/rubik/rubik-v20-latin-ext_latin-300.woff2") format("woff2"),
    url("./../fonts/rubik/rubik-v20-latin-ext_latin-300.woff") format("woff");
}

/* roboto-mono-300 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  src:
    url("./../fonts/roboto-mono/roboto-mono-v13-latin-300.woff2") format("woff2"),
    url("./../fonts/roboto-mono/roboto-mono-v13-latin-300.woff") format("woff");
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

html {
  cursor: initial;
  letter-spacing: 0.05em;
}

* {
  transition: border 200ms linear, background-color 200ms linear;
}

.logotype-big {
  max-width: 240px;
  max-height: 40px;
}

.logotype-small {
  max-width: 16px;
  max-height: 16px;
}

/* Visually warn developers about sensitive input fields missing preventSavingInputsToUserData */
:is(input, textarea):is(
[type="password"], [name="password"], [name="privatekey"], [name="mnemonic"], #privatekey, #mnemonic
):not([autocomplete="off"]),
/* Parent form should have preventSavingInputsToUserData too */
form:not([autocomplete="off"]) :is(input, textarea):is(
[type="password"], [name="password"], [name="privatekey"], [name="mnemonic"], #privatekey, #mnemonic
) {
  background: rgb(255 0 0) !important;
  border: 1px solid rgb(255 0 0) !important;
  content: "Use preventSavingInputsToUserData on sensitive inputs and parent form";
}
